.react-responsive-modal-modal {
    width: 500px;
  
}

.menu-title{
    font-size: 16px;
}

.aside-fixed .aside{
    overflow-y: scroll !important;
}